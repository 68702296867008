import React, { FC } from "react";
import "./styles.scss";

const customInput: FC<any> = ({
  ref,
  type,
  label,
  onBlur,
  onFocus,
  required,
  value,
  name,
  placeholder,
  onChange,
  rightAddon,
  className,
  error,
  disabled,
  dataTip,
}) => {
  return (
    <div className={className || ""}>
      {label && <span className="form-item-label">{label}</span>}
      <div
        className={`${rightAddon ? "form-item-with-right-addon" : "form-item"}`}
        data-tip={dataTip}
      >
        <input
          ref={ref ? ref : null}
          type={type ? type : "text"}
          autoCapitalize="none"
          name={name}
          placeholder={placeholder}
          value={value}
          className={`input-field ${error ? "error-border" : ""}`}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          disabled={disabled ? true : false}
          required={required ? required : false}
        />
        {rightAddon}
      </div>
    </div>
  );
};

export default customInput;
