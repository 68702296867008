import React, {FC} from 'react';

import {arrowDownIcon} from '../../../images/svgIcons';

import Select from '../../select';
import Radio from '../../radio';
import {options} from '../pricingItem';
import {IPaymentItem} from '../../types';

import './styles.scss';

const PaymentItem: FC<IPaymentItem> = ({
  name,
  selectedValue,
  price,
  showPackeges,
  onChange,
  selected,
  checked,
  onRadioChange,
  disabled,
  handelSelectChange,
  user,
  ltdPrice,
  ltdPriceGold,
  ltdPriceSilver,
  ltdPriceAdjusted,
}) => {
  return (
    <>
      <div
        className={`payment_toolbar d-flex justify-content-between align-items-center ${
          !selected ? 'no-selected' : ''
        } ${disabled ? 'disabled' : ''}`}
      >
        <div className="d-flex align-items-center">
          <Radio checked={checked} onClick={onRadioChange} className={'mt-2 pt-1'} />
          <div className="">
            <div>
              <div className="d-flex align-items-center automations-credits">
                <div className="package" onClick={onRadioChange}>
                  {name[0] + name.slice(1).toLocaleLowerCase()}
                </div>
                <div className={`${name.toLocaleUpperCase() === 'GOLD' ? 'visible' : 'invisible'}`}>
                  <div className="credits">Automation Credits</div>
                  <div>
                    <Select
                      options={options}
                      value={selectedValue}
                      onChange={val => handelSelectChange(val)}
                      className="pricing-item-select"
                    />
                  </div>
                </div>
              </div>
              <div className="purchase">
                {/* $
                {name === "Gold" && selectedValue
                  ? selectedValue.value * price
                  : price}
                /mo,  */}
                $
                {(name === 'Gold' || name === 'GOLD') && selectedValue
                  ? ltdPriceGold
                    ? selectedValue.value * ltdPriceGold - ltdPriceAdjusted
                    : selectedValue.value * price
                  : (name === 'Silver' || name === 'SILVER') && selectedValue
                  ? ltdPriceSilver
                    ? ltdPriceSilver
                    : price
                  : price}{' '}
                {/* ${name.toLocaleLowerCase() === 'gold' && selectedValue ? selectedValue.value * price : price}  */}
                {name.includes('trial') ? 'billed after 7-day free trial' : 'billed upon purchase'}
              </div>
            </div>
          </div>
        </div>
        {selected &&
          (user?.pricing_package === 'FREE' || !user?.pricing_package || user?.pricing_package === 'BRONZE') && (
            <div className={`change-plan ${showPackeges ? 'rotate' : ''}`} onClick={onChange}>
              <span>Change Plan</span> {arrowDownIcon}
            </div>
          )}
      </div>
    </>
  );
};

export default PaymentItem;
