import React from "react";
import CustomProgress from "../../custom-progress-tooltip";
import "./styles.scss";

const PaymentLoader = () => (
  <div className="text-center h-100">
    <div className="payment-wait">Please wait</div>
    <div className="payment-process">Payment in process</div>
    <CustomProgress noMessage />
  </div>
);

export default PaymentLoader;
