import React, {FC} from 'react';
import './styles.scss';
interface IRadio {
  checked?: boolean;
  value?: string;
  name?: string;
  onChange?: (val: any) => void;
  onClick?: () => void;
  className?: string;
  border?: boolean;
  label?: string;
}
const Radio: FC<IRadio> = ({checked, name, value, onChange, onClick, className, border, label}) => (
  <div className={`radio-wrapper ${className ? className : ''} ${border ? 'border-color' : ''}`}>
    <div className="custom-control custom-radio custom-control-inline">
      <input
        type="radio"
        id="rd_2"
        name={name}
        className="custom-control-input cursor-pointer d-none"
        value={value}
        checked={checked}
        onChange={onChange}
      />

      <label className="custom-control-label red cursor-pointer" htmlFor="rd_2" onClick={onClick}>
        {label}
      </label>
    </div>
  </div>
);

export default Radio;
