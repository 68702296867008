import React, {FC} from 'react';
import {thankYou} from '../../../images/svgIcons';
import Button from '../../button';
import './styles.scss';

const PaymentSuccess: FC<{paypal?: boolean; trial?: boolean}> = ({paypal, trial}) => (
  <div style={{maxWidth: '500px', margin: 'auto'}}>
    <div className="mx-auto" style={{width: 'fit-content', marginTop: '4vh'}}>
      <div className="d-flex align-items-center justify-content-center">{thankYou}</div>
    </div>
    <h3 className="text-center mt-5" style={{fontWeight: 700, color: '#00042a', fontSize: '24px'}}>
      Thank you
    </h3>
    <h3 className="text-center mt-2" style={{fontSize: '18px', color: '#516077', fontWeight: 400}}>
      {trial
        ? 'Your trial has been successfully activated.'
        : paypal
        ? 'Your payment is being processed (can take a few minutes to show in your account).'
        : 'Payment successfully completed.'}
    </h3>
    <div className="d-flex align-items-center justify-content-center mt-4">
      <Button
        onClick={() => {
          window.location.host.includes('localhost')
            ? (window.location.href = 'http://localhost:3000/dashboard')
            : window.location.host.includes('37.186.119.181')
            ? (window.location.href = 'http://37.186.119.181:3279/dashboard')
            : (window.location.href = 'https://dash.hexomatic.com/dashboard');
        }}
      >
        Go to Dashboard
      </Button>
    </div>
  </div>
);

export default PaymentSuccess;
