import React, {FC} from 'react';
import {checkedIconPrice, infinityIcon} from '../../../images/svgIcons';
import {IPricingItem} from '../../types';
import Select from '../../select';
import './styles.scss';

export const options = [
  {label: '10000', value: 1},
  {label: '20000', value: 2},
  {label: '30000', value: 3},
  {label: '40000', value: 4},
  {label: '50000', value: 5},
  {label: '60000', value: 6},
  {label: '70000', value: 7},
  {label: '80000', value: 8},
  {label: '90000', value: 9},
  {label: '100000', value: 10},
];

const PricingItem: FC<IPricingItem> = ({
  isBold,
  text,
  isChecked,
  unlimited,
  number,
  select,
  selectedValue,
  onChange,
  disableSelect,
}) => {
  return (
    <div className="pricing-item">
      {isBold ? (
        <div className="bold-item">{text}</div>
      ) : (
        <div className="d-flex">
          <div className="d-flex align-items-center justify-content-center">
            <div className={`${isChecked || unlimited ? 'icon-wrapper text-center' : 'number-wrapper m-auto'}`}>
              {isChecked ? checkedIconPrice : unlimited ? infinityIcon : number}
            </div>
          </div>
          <div className="text-wrapper ml-2 px-0">
            {select && onChange ? (
              <Select
                options={options}
                value={selectedValue}
                onChange={(val: any) => onChange && onChange(val)}
                className="pricing-item-select"
                disabled={disableSelect}
              />
            ) : (
              text
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default PricingItem;
