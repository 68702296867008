import React, {FC, memo} from 'react';
import {paypalBtnIcon} from '../../../images/svgIcons';
import Button from '../../button';

const PaymentFooter: FC<{
  confirm: () => void;
  paypal: boolean;
  loading: boolean;
  disabled?: boolean;
  pendingPayment?: boolean;
}> = ({confirm, paypal, loading, disabled, pendingPayment}) => (
  <>
    <div className="row">
      <div className="col-12 text-center" title={pendingPayment ? 'You have pending payment' : ''}>
        <Button
          className={`confirm-and-pay ${paypal ? 'paypal' : ''}`}
          loading={loading}
          onClick={confirm}
          disabled={loading || disabled || pendingPayment}
        >
          {paypal ? (
            <>
              <span className="text">Pay with</span> <span className="icon">{paypalBtnIcon}</span>
            </>
          ) : (
            'Confirm and Pay'
          )}
        </Button>
      </div>
    </div>
    {/* <div className="payment-text">
      {!paypal && 'We do not store your credit card information in our system at any point in time.'} Your payment
      information is securely transmitted to {paypal ? 'Paypal' : 'Stripe'}. If you have any questions, please don't
      hesitate to contact us at billing@hexact.io
    </div> */}
  </>
);

export default memo(PaymentFooter);
