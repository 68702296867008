import React, {FC, useEffect, useState} from 'react';
import './styles.scss';

interface IAppProps {
  percent?: number;
  text?: string;
  noMessage?: boolean;
  time?: number;
  className?: string;
  func?: any;
}

const CustomProgress: FC<IAppProps> = ({percent, text, noMessage, time, className, func}) => {
  const [count, setCount] = useState(0);
  //@ts-ignore
  var interval = null;
  useEffect(() => {
    interval = setInterval(function() {
      setCount(count => {
        if (count + 2 >= 96) {
          //@ts-ignore
          clearInterval(interval);
        }
        return count + 2;
      });
    }, time || 100);

    return () => {
      //@ts-ignore
      clearInterval(interval);
    };
  }, []);
  useEffect(() => {
    if (count === 96) func && func();
  }, [count]);
  return (
    <section className={`section ${className ? className : ''}`}>
      <div className={`final__animation-bar-2 ${noMessage ? '' : 'show-tooltip'}`}>
        <span data-label={text || percent || count + '%'} style={{width: percent || count + '%'}}></span>
      </div>
    </section>
  );
};

export default CustomProgress;
