import React, {FC, FormEvent} from 'react';
import './styles.scss';

interface TextAreaProps {
  value: string;
  onChange: (e: FormEvent) => void;
  placeholder?: string;
  name?: string;
  rows?: number;
  className?: string;
  onBlur?: Function;
  onFocus?: Function;
  disableResize?: boolean;
  maxLength?: number;
  isShowLength?: boolean;
  disabled?: boolean;
}

export const TextArea: FC<TextAreaProps> = ({
  value,
  onChange,
  placeholder,
  name,
  rows,
  className,
  onBlur,
  onFocus,
  disableResize,
  maxLength,
  isShowLength,
  disabled,
}) => (
  <div className="position-relative ">
    <textarea
      className={`textarea ${className ? className : ''} ${disableResize ? 'disable-resize' : ''}`}
      name={name}
      placeholder={placeholder}
      value={value}
      rows={rows || 6}
      onChange={onChange}
      onBlur={() => onBlur && onBlur()}
      onFocus={() => onFocus && onFocus()}
      maxLength={maxLength}
      disabled={disabled}
    />
    {maxLength && isShowLength && (
      <div className="position-absolute text-size">
        {value.length}/{maxLength}
      </div>
    )}
  </div>
);
