import React, {FC, useState} from 'react';
import {CardFormComponentProps} from './types';
import {eyeIcon} from '../../images/svgIcons';
import {formatCreditCardNumber, formatExpirationDate, formatCVC} from './helpers';
import CustomInput from '../customInput/index';
import Visa from '../../images/payments/Visa.png';
import AmericanExpress from '../../images/payments/AmericanExpress.png';
import MasterCard from '../../images/payments/MasterCard.png';
import UnionPay from '../../images/payments/UnionPay.png';
import Discover from '../../images/payments/Discover.png';
import DinersClub from '../../images/payments/DinersClub.png';
import JCB from '../../images/payments/JCB.png';

const CreditCardFormComponent: FC<CardFormComponentProps> = ({
  cardData,
  onFieldChange,
  onFieldBlur,
  onFieldFocus,
  disabled,
  fieldError,
  hideCards,
}) => {
  const [showCVV, setShowCVV] = useState(false);
  return (
    <>
      <div className="profile-payment-inputs" style={{opacity: disabled ? '0.5' : 1}}>
        <div>
          <CustomInput
            label={'Cardholder’s Name'}
            name="name"
            placeholder="Name on the card"
            value={cardData.name}
            onChange={onFieldChange}
            onFocus={onFieldFocus}
            onBlur={onFieldBlur}
            required
            disabled={disabled}
            error={fieldError && !cardData.name}
          />
        </div>
        <div>
          <CustomInput
            label={'Credit/Debit Card Number'}
            name="number"
            placeholder="Card number"
            value={formatCreditCardNumber(cardData.number)}
            onChange={onFieldChange}
            onFocus={onFieldFocus}
            onBlur={onFieldBlur}
            required
            disabled={disabled}
            error={fieldError && !cardData.number}
          />
        </div>
        <div className="row">
          <div className="col-12 col-lg-6 col-sm-6">
            <CustomInput
              label={'Expiration'}
              name="expiry"
              placeholder="MM/YY"
              value={formatExpirationDate(cardData.expiry)}
              onChange={onFieldChange}
              onFocus={onFieldFocus}
              onBlur={onFieldBlur}
              required
              disabled={disabled}
              error={fieldError && !cardData.expiry}
            />
          </div>
          <div className="col-12 col-lg-6 col-sm-6">
            <div className="form-item">
              <span className="label">Security code</span>
              <div className="input-wrapper">
                <input
                  type={showCVV ? 'text' : 'password'}
                  name="cvc"
                  placeholder="CVV/CVC"
                  className={`input-field mt-1 ${fieldError && !cardData.cvc ? 'error-border' : ''}`}
                  value={formatCVC(cardData.cvc, cardData.number)}
                  onChange={onFieldChange}
                  onFocus={onFieldFocus}
                  onBlur={onFieldBlur}
                  required
                  disabled={disabled}
                />
                <div
                  className={`eye-icon ${showCVV ? ' active' : ''}`}
                  onClick={e => !disabled && setShowCVV(!showCVV)}
                >
                  {eyeIcon}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!hideCards && (
        <div className="payment-icons text-center d-flex align-items-center justify-content-center">
          <img src={Visa} alt="Visa" className="m-1" style={{width: '40px'}} />
          <img src={MasterCard} alt="Master Card" className="m-1" />
          <img src={AmericanExpress} alt="American Express" className="m-1" />
          <img src={Discover} alt="Discover" className="m-1" />
          <img src={JCB} alt="JCB" className="m-1" />
          <img src={DinersClub} alt="Diners Club" className="m-1" />
          <img src={UnionPay} alt="Union Pay" className="m-1" />
        </div>
      )}
    </>
  );
};

export default CreditCardFormComponent;
