import React, {FC, memo} from 'react';
import {cardIcon, defaultCardIcon, paypalIcon} from '../../../images/svgIcons';
import Radio from '../../radio';
import './styles.scss';

const ChooseCard: FC<{
  editCardInfo: number;
  onChange: (val: number) => void;
  defaultCard?: boolean;
  paypal?: boolean;
  stripe?: boolean;
}> = ({editCardInfo, onChange, defaultCard, paypal, stripe}) => (
  <div className="d-md-flex radio-buttons-wrapper justify-content-center">
    {stripe && (
      <>
        {defaultCard && (
          <div
            className={`element text-center d-flex justify-content-between ${
              editCardInfo === 1 ? 'active' : ''
            } count-${Number(!!paypal) + Number(!!defaultCard)}`}
          >
            <Radio checked={editCardInfo === 1} border onClick={() => editCardInfo !== 1 && onChange(1)} />
            <div className="radio-label default-card" onClick={() => editCardInfo !== 1 && onChange(1)}>
              {defaultCardIcon}
            </div>
            <div className="element-title">Default payment</div>
          </div>
        )}
        <div
          className={`element text-center d-flex justify-content-between ${
            editCardInfo === 2 ? 'active' : ''
          } count-${Number(!!paypal) + Number(!!defaultCard)}`}
        >
          <Radio checked={editCardInfo === 2} border onClick={() => editCardInfo !== 2 && onChange(2)} />
          <div className="radio-label card-icon" onClick={() => editCardInfo !== 2 && onChange(2)}>
            {cardIcon}
          </div>
          <div className="element-title">Credit card</div>
        </div>
      </>
    )}

    {paypal && (
      <div
        className={`element text-center d-flex justify-content-between ${
          editCardInfo === 3 ? 'active' : ''
        } count-${Number(!!paypal) + Number(!!defaultCard)}`}
      >
        <Radio checked={editCardInfo === 3} border onClick={() => editCardInfo !== 3 && onChange(3)} />
        <div className="radio-label paypal-icon" onClick={() => editCardInfo !== 3 && onChange(3)}>
          {paypalIcon}
        </div>
        <div className="element-title">PayPal</div>
      </div>
    )}
  </div>
);

export default memo(ChooseCard);
