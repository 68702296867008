import React, {FC, useState, useLayoutEffect, useEffect, useCallback} from 'react';
import {arrowDownIcon} from '../../images/svgIcons';
import useClickOutside from '../../hooks/useClickOutside';
import {SelectTypes} from '../types';
import './styles.scss';
import { Input } from '../form';

const Select: FC<SelectTypes> = ({
  value,
  options,
  onChange,
  children,
  disabled,
  manySelect,
  className,
  placeHolder,
  bottom,
  icon,
  showLeft,
  showRight,
  empty,
  searchable
}) => {
  const [showInput, setShowInput] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [showOptions, selectRef, setShowOptions] = useClickOutside();
  const [newOptions, setNewOptions] = useState(options);
  const [filteredOptions, setFilteredOptions] = useState(options);
  // const [docHeigth, setDocHeight] = useState<number>(0)
  // const [elOffsetHeigth, setElOffsetHeigth] = useState<number>(0)

  // useLayoutEffect(() => {
  //   setDocHeight(document.documentElement.clientHeight)
  // }, [document])

  // useLayoutEffect(() => {
  //   if (selectRef && selectRef.current) {
  //     const dimensions = selectRef.current.getBoundingClientRect();
  //   }
  // }, [selectRef])

  // const res = docHeigth - elOffsetHeigth

  useEffect(() => {
    const new_option = !searchable ? options : filteredOptions;
    setNewOptions(new_option);
  }, [options, filteredOptions, searchable, value]);

  const handleChangeInput = useCallback(
    (e: any) => {
      setSearchText(e.target.value);
      const newOpt = [...options];
      const fil = newOpt.filter((op: { label: string }) =>
        op.label.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase()),
      );
      setFilteredOptions(e.target.value && e.target.value.trim() ? fil : options);
      onChange(e.target.value);
    },
    [options, onChange],
  );

  const handleHideInput = useCallback(() => {
    searchText && setSearchText('');
    showInput && setShowInput(false);
  }, [showInput]);

  const handleShowInput = useCallback(() => {
    !showInput && setShowInput(true);
  }, [showInput]);

  return (
    <div
      className={`custom-universal-select ${className ? className : ''} ${
        showLeft ? 'show-left' : showRight ? 'show-right' : ''
      }`}
      ref={selectRef}
    >
      {showLeft || showRight ? (
        <span onClick={() => !disabled && setShowOptions(!showOptions)}>{icon}</span>
      ) : (
        <button
          className={`select ${showOptions ? 'border-color' : ''} ${empty ? 'border-empty' : ''}`}
          disabled={disabled}
          onClick={() => !disabled && setShowOptions(!showOptions)}
        >
          {searchable && (
            <div className={`${showInput ? 'show' : 'hide'}`}>
              <Input
                value={searchText}
                onChange={handleChangeInput}
                className="no-border-input"
                placeholder={
                  typeof value === 'object' ? value?.label : value || ''
                }
                onFocus={handleShowInput}
                onBlur={handleHideInput}
                onKeyDown={handleShowInput}
                type='text'
                name='country'
                autocompleteOff
              />
            </div>
          )}
          {searchable && placeHolder && !value && !showInput && <span style={{color: '#9ca3b9'}}>{placeHolder}</span>}
          {!searchable && placeHolder && !value ? (
            <span style={{color: '#9ca3b9'}}>{placeHolder}</span>
          ) : (
            <>
              {
                !showInput && <span
                  className={`selected-value noselect ${
                    value && (value.label === 'Select' || value.label === 'Please select one') ? 'text-secondary' : ''
                  }`}
                >
                  {typeof value === 'string' ? value : value && value.label}
                </span>
              }
              {icon ? icon : <span className="arrow-icon">{arrowDownIcon}</span>}
            </>
          )}
          
        </button>
      )}
      {showOptions && (
        <div className={`options`}>
          {children
            ? children
            : newOptions && newOptions.length > 1 ?
            newOptions.map((item: any, index: number) => (
                <p
                  className={`option ${item.value}
                  ${
                    value === item.label ||
                    (manySelect && value && value.find((v: any) => v === item.value)) ||
                    (value === 'all' && item.value === '') ||
                    (typeof value !== 'string' && value && value.value === item.value)
                      ? !manySelect
                        ? ''
                        : 'selected'
                      : ''
                  } ${value.value === item.value ? 'active' : ''}`}
                  key={item.label}
                  onClick={() => {
                    onChange(item);
                    !manySelect && setShowOptions(false);
                  }}
                >
                  {item.label}
                </p>
              )) : <div className='text-center'>No result</div>}
        </div>
      )}
    </div>
  );
};

export default React.memo(Select);
