import React, {FC, useState, useEffect} from 'react';
import axios from 'axios';
import PaymentItem from '../paymentHeader';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';
import PaymentFooter from '../paymentFooter';
import PaymentSuccess from '../paymentSuccess';
import PaymentLoader from '../paymentLoader';
import {IPayment, CardStateData} from '../../types';
import {useMutation, useQuery} from '@apollo/react-hooks';
import {ADD_CARD, SUBSCRIBTION} from '../../../graphql/mutations';
import {LIST_CUSTOMER_CARD} from '../../../graphql/queries';
import CreditCardForm from '../../creditCardForm';
import ChooseCard from '../paymentChooseCard';
import {closeIcon, paypalBtnIcon} from '../../../images/svgIcons';
import './styles.scss';

export const removeItem = () => {
  localStorage.removeItem('packageInfo');
};

const cardsFirstNumbers: any = {
  VISA: '4242',
  MASTERCARD: '5555',
  'AMERICAN EXPRESS': '3782',
  DISCOVER: '6011',
  DINERSCLUB: '3056',
  JCB: '3566',
  UNIONPAY: '6200',
  'DINERS CLUB': '3056',
};

const PaymentInfo: FC<IPayment> = ({
  name,
  selectedValue,
  price,
  packeges,
  user,
  setShowPopup,
  id,
  refetch,
  interval,
  paypal_id,
  ltdPrice,
  ltdPriceGold,
  ltdPriceSilver,
  ltdPriceAdjusted
}) => {
  //states
  let secondWindow: any;
  let int: any;
  const [showPaypalMessage, setShowPaypalMessage] = useState(false);
  const [el, setEl] = useState(null as any);
  const [stripe, setStripe] = useState<any>(null);
  const [cardId, setCardId] = useState('');
  const [paypalToken, setPaypalToken] = useState('');
  const [selectedPackege, setSelectedPackage] = useState({
    name,
    price,
    id,
    paypal_id,
    trial: name.includes('7-day free trial')
  });
  const [customLoading, setCustumLoading] = useState(false);
  const [showPackeges, setShowPackeges] = useState(false);
  const [selectValue, setSelectValue] = useState(selectedValue);

  const [cardInfo, setCardInfo] = useState({
    name: '',
    number: '',
    expiry: '',
    cvc: '',
    focus: '',
  });
  const [defaultCardInfo, setDefaultCardInfo] = useState({
    name: '',
    number: '',
    expiry: '',
    cvc: '',
    focus: '',
  });
  const [editCardInfo, setEditCardInfo] = useState(2);
  const [err, setErr] = useState('');
  const [success, setSuccess] = useState(false);
  const [paidWithPaypal, setPaidWithPaypal] = useState(false);
  const [fieldError, setFieldError] = useState(false);
  //queries
  const {data: creditCards, loading: cardsLoading, refetch: refetchCardList} = useQuery(LIST_CUSTOMER_CARD, {
    fetchPolicy: 'no-cache',
    variables: {
      settings: {
        limit: 100,
      },
    },
  });
  const [addPaymentMethod, {loading}] = useMutation(ADD_CARD);
  const [createSubscription, {loading: subscriptionLoading}] = useMutation(SUBSCRIBTION);

  //useEffects

  useEffect(() => {
    refetch();
    removeItem();
    //@ts-ignore
    window['Stripe'].setPublishableKey(
      window.location.hostname.includes('hexomatic.com')
        ? 'pk_live_8KwSrH69LnrJea7rrCH7Phn1'
        : 'pk_test_awe3SQdhRsX7VjRATH8uPbyW',
    );
    setStripe(
      //@ts-ignore
      window['Stripe'](
        window.location.hostname.includes('hexomatic.com')
          ? 'pk_live_8KwSrH69LnrJea7rrCH7Phn1'
          : 'pk_test_awe3SQdhRsX7VjRATH8uPbyW',
      ),
    );
  }, []);

  useEffect(() => {
    if (
      creditCards &&
      creditCards.Billing &&
      creditCards.Billing.listCustomerCards &&
      creditCards.Billing.listCustomerCards.cards
    ) {
      const defaultCard =
        creditCards.Billing.listCustomerCards.cards.length === 1
          ? creditCards.Billing.listCustomerCards.cards[0]
          : creditCards.Billing.listCustomerCards.cards.find((item: any) => item.isDefault === 'true');

      if (defaultCard) {
        setCardId(defaultCard.id);
        setDefaultCardInfo({
          cvc: '***',
          expiry: '**/**',
          focus: defaultCard.brand,
          name: '**** ****',
          number:
            (cardsFirstNumbers[defaultCard.brand.toUpperCase()]
              ? cardsFirstNumbers[defaultCard.brand.toUpperCase()]
              : '****') +
            '********' +
            defaultCard.lastDigits,
        });
        setEditCardInfo(1);
      }
    }
  }, [creditCards]);

  useEffect(() => {
    setErr('');
  }, [editCardInfo]);

  useEffect(() => {
    if (success) refetch();
  }, [success]);
  useEffect(() => {
    if (err) {
      setCustumLoading(false);
      setTimeout(() => setErr(''), 10000);
    }
  }, [err]);

  // useEffect(() => {
  //   if (!showPaypalMessage && localStorage.getItem('payment_success')) {
  //     setSuccess(true);
  //     localStorage.removeItem('payment_success');
  //     analytics();
  //   }
  // }, [showPaypalMessage]);

  //functions
  const handlePaymentFormSubmit = async () => {
    setErr('');
    const obj = {...cardInfo, focus: 'name'};
    if (Object.values(obj).filter(item => !item).length > 0) {
      setFieldError(true);
      return;
    }
    setFieldError(false);
    setCustumLoading(true);
    handlePayment(obj);
  };

  const handlePayment = async (cardData: CardStateData) => {
    const expiry = cardData.expiry.split('/');
    try {
      //@ts-ignore
      window.Stripe.card.createToken(
        {
          number: cardData.number,
          exp_month: +expiry[0],
          exp_year: +expiry[1].slice(0, 2),
          cvc: cardData.cvc,
        },
        //@ts-ignore
        (status, response) => {
          if (status === 200) {
            if (response && response.id) addCard(cardData, response.id);
            else setErr('Invalid parameters.');
          } else setErr('Invalid parameters.');
        },
      );
    } catch {
      setErr('Something went wrong');
    }
  };
  const addCard = async (cardData: CardStateData, id: string) => {
    try {
      const res = await addPaymentMethod({
        variables: {
          settings: {
            email: user && user.email,
            name: cardData.name,
            source: id,
          },
        },
      });
      const errors =
        res && res.data && res.data.BillingOps && res.data.BillingOps.addCard
          ? res.data.BillingOps.addCard.error_code
          : null;
      if (errors) {
        setErr(errors.includes('limit_reached') ? 'Attached cards limit reached' : errors.includes('Try later') ? 'Try later' : 'Invalid card');
      };
      if (res && res.data && res.data.BillingOps && res.data.BillingOps.addCard && res.data.BillingOps.addCard.cardId) {
        await handleUpgrade(res.data.BillingOps.addCard.cardId);
      }
      refetchCardList();
      setCustumLoading(false);
    } catch {
      setErr('Something went wrong.');
    }
  };

  const analytics = () => {
    if (window.location.href.includes('hexomatic.com')) {
      //@ts-ignore
      window.dataLayer.push({
        event: 'purchase-button-click',
        purchaseOrderId: new Date().toISOString(),
        purchaseConversionValue: `${(selectedPackege.price || 0) * (selectValue?.value || 1)}`,
      });
      //@ts-ignore
      window.dataLayer.push({
        event: 'eec.purchase',
        ecommerce: {
          currencyCode: 'USD',
          purchase: {
            actionField: {
              id: `${selectedPackege.name}_${new Date().toISOString()}`,
              revenue: `${(selectedPackege.price || 0) * (selectValue?.value || 1)}`,
            },
            products: [
              {
                item_id: `${selectedPackege.name}_${new Date().toISOString()}`,
                item_name: `${selectedPackege.name}_${interval?.toUpperCase()}`,
                price: `${selectedPackege.price || 0}`,
                quantity: selectValue?.value || 1,
              },
            ],
          },
        },
      });
    }
  };

  const handleUpgrade = async (card: string) => {
    try {
      setCustumLoading(true);
      setSuccess(false);
      setPaidWithPaypal(false);
      setErr('');
      setFieldError(false);
      const res = await createSubscription({
        variables: {
          settings: {
            productId: selectedPackege.id,
            payment_type: 'STRIPE',
            cardId: card,
            credits_pricing_package_count: selectedPackege?.name?.toLocaleLowerCase() === 'gold' ? selectValue?.value : 1,
            startTrial: !!selectedPackege.name.includes('trial'),
          },
        },
      });
      if (
        res &&
        res.data &&
        res.data.HexomaticUserOps &&
        res.data.HexomaticUserOps.updateUserHexomaticPackage &&
        res.data.HexomaticUserOps.updateUserHexomaticPackage.clientSecret
      ) {
        //@ts-ignore
        const {paymentIntent, error} = await stripe.confirmCardPayment(
          res.data.HexomaticUserOps.updateUserHexomaticPackage.clientSecret,
          {
            payment_method: res.data.HexomaticUserOps.updateUserHexomaticPackage.card,
          },
        );
        if (paymentIntent && paymentIntent.status === 'succeeded') {
          setSuccess(true);
          setPaidWithPaypal(false);
          analytics();
        } else {
          setErr(error.includes('Try later') ? 'Try later' : 'Payment failed');
        }
        setCustumLoading(false);
        return;
      }
      if (
        res &&
        res.data &&
        res.data.HexomaticUserOps &&
        res.data.HexomaticUserOps.updateUserHexomaticPackage &&
        res.data.HexomaticUserOps.updateUserHexomaticPackage.error_code
      ) {
        setErr(res &&
          res.data &&
          res.data.HexomaticUserOps &&
          res.data.HexomaticUserOps.updateUserHexomaticPackage &&
          res.data.HexomaticUserOps.updateUserHexomaticPackage.error_code.includes('Try later') ? 'Try later' : 'Payment failed');
      } else {
        setSuccess(true);
        setPaidWithPaypal(false);
        analytics();
      }
      setCustumLoading(false);
    } catch (err) {
      setErr('Something went wrong.');
    }
  };

  const upgradeByPaypal = async () => {
    const y = (window.outerHeight - 470) / 2;
    const x = (window.outerWidth - 400) / 2;
    secondWindow = window.open(
      '',
      'secondary',
      `width=400,height=470,top=${y},left=${x}`,
    );
    const {data} = await createSubscription({
      variables: {
        settings: {
          productId: selectedPackege.paypal_id,
          payment_type: 'PAYPAL',
          return_url: window.location.href,
          credits_pricing_package_count: selectedPackege?.name?.toLocaleLowerCase() === 'gold' ? selectValue?.value : 1,
          startTrial: !!selectedPackege.name.includes('trial'),
        },
      },
    });
    if (
      data &&
      data.HexomaticUserOps &&
      data.HexomaticUserOps.updateUserHexomaticPackage &&
      data.HexomaticUserOps.updateUserHexomaticPackage.approveUrl
    ) {
      secondWindow && (secondWindow.location = data.HexomaticUserOps.updateUserHexomaticPackage.approveUrl);
      const token = data.HexomaticUserOps.updateUserHexomaticPackage.approveUrl;
      token && setPaypalToken(token.slice(token.indexOf('token=') + 6));
      setEl(secondWindow);
      setShowPaypalMessage(true);
      int = setInterval(() => {
        if (!secondWindow || secondWindow.closed) {
          clearInterval(int);
          setShowPaypalMessage(false);
          setEl(null);
          console.log('done');
        }
      });
    } else {
      secondWindow && secondWindow.close();
    }
  };

  const handleCloseModal = () => {
    setShowPopup(false);
    removeItem();
  };

  const handleNotShowPackages = () => {
    showPackeges && setShowPackeges(false);
  };
  const handleChangeShowPackages = () => {
    setShowPackeges(!showPackeges);
  };
  const eventStop = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const onRadioChange = (item: any) => {
    item.name !== selectedPackege.name &&
      !item.disabled &&
      setSelectedPackage({
        name: item.name,
        price: item.price,
        id: item.id,
        paypal_id: item.paypal_id,
        trial: item.trial,
      });
    setShowPackeges(false);
  };

  const handleConfirm = () =>
    editCardInfo === 3
      ? upgradeByPaypal()
      : defaultCardInfo.name && editCardInfo === 1
      ? handleUpgrade(cardId)
      : handlePaymentFormSubmit();

  const handleShowSecondWindow = () => el && el.focus();
  const handleCloseSecondWindow = () => el && el.close();

  useEffect(() => {
    const paypal = !user?.stripe_subscription_id;
    const stripe = !user?.paypal_subscription_id
    if (paypal && !stripe) {
      setEditCardInfo(3);
    }
  }, [user]);

  const getSuccessMessage = async () => {
    const res = await axios('https://api.hexomatic.com/paypal/check', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      params: {
        token: !paypalToken.includes('BA-') ? paypalToken : undefined,
        ba_token: paypalToken.includes('BA-') ? paypalToken : undefined,
      },
    });
    if (res && res.data && res.data.success) {
      setSuccess(true);
      setPaidWithPaypal(true);
      analytics();
    }
  };

  useEffect(() => {
    if (!showPaypalMessage && paypalToken) {
      getSuccessMessage();
    }
  }, [showPaypalMessage, paypalToken]);

  return (
    <>
      {!showPaypalMessage ? (
        <div className="payment" onClick={handleNotShowPackages}>
          <span onClick={handleCloseModal} className="modal-close">
            {
              <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M4.88111 4.00011L7.8722 1.00889C7.95447 0.926545 7.99987 0.816691 8 0.699553C8 0.58235 7.9546 0.472366 7.8722 0.390155L7.61008 0.128106C7.52767 0.0455695 7.41782 0.000366211 7.30055 0.000366211C7.18348 0.000366211 7.07363 0.0455695 6.99122 0.128106L4.00013 3.11913L1.00891 0.128106C0.926634 0.0455695 0.816715 0.000366211 0.699512 0.000366211C0.582439 0.000366211 0.47252 0.0455695 0.390244 0.128106L0.128 0.390155C-0.0426667 0.560821 -0.0426667 0.838415 0.128 1.00889L3.11915 4.00011L0.128 6.9912C0.0456585 7.07367 0.000325203 7.18352 0.000325203 7.30066C0.000325203 7.4178 0.0456585 7.52765 0.128 7.61006L0.390179 7.87211C0.472455 7.95458 0.582439 7.99985 0.699447 7.99985C0.81665 7.99985 0.926569 7.95458 1.00885 7.87211L4.00006 4.88102L6.99115 7.87211C7.07356 7.95458 7.18341 7.99985 7.30049 7.99985H7.30062C7.41776 7.99985 7.52761 7.95458 7.61002 7.87211L7.87213 7.61006C7.95441 7.52772 7.9998 7.4178 7.9998 7.30066C7.9998 7.18352 7.95441 7.07367 7.87213 6.99126L4.88111 4.00011Z"
                  fill="#8C979D"
                />
              </svg>
            }
          </span>
          {!customLoading && !loading ? (
            !success ? (
              <>
                <div onClick={eventStop}>
                  <PaymentItem
                    name={selectedPackege.name}
                    selectedValue={selectValue}
                    price={selectedPackege.price}
                    ltdPrice={ltdPrice}
                    ltdPriceGold={ltdPriceGold}
                    ltdPriceSilver={ltdPriceSilver}
                    ltdPriceAdjusted={ltdPriceAdjusted}
                    showPackeges={showPackeges}
                    onChange={handleChangeShowPackages}
                    selected
                    checked
                    handelSelectChange={setSelectValue}
                    user={user}
                    id={id}
                  />
                  {showPackeges && (
                    <div className="packeges-list">
                      {packeges &&
                        packeges.length > 0 &&
                        packeges.map(
                          item =>
                            !item.disabled && (
                              <PaymentItem
                                disabled={item.disabled}
                                name={item.name}
                                selectedValue={selectValue}
                                price={item.price}
                                showPackeges
                                ltdPrice={ltdPrice}
                                ltdPriceGold={ltdPriceGold}
                                ltdPriceSilver={ltdPriceSilver}
                                ltdPriceAdjusted={ltdPriceAdjusted}
                                checked={item.name.toLocaleLowerCase() === selectedPackege.name.toLocaleLowerCase()}
                                handelSelectChange={setSelectValue}
                                onRadioChange={() => onRadioChange(item)}
                                id={id}
                              />
                            ),
                        )}
                    </div>
                  )}
                </div>
                {cardsLoading ? (
                  <div className="d-flex align-items-center mt-5 pt-5 justify-content-center">
                    <FontAwesomeIcon icon={faSpinner} className="button-loading" />
                  </div>
                ) : (
                  <>
                    {!cardsLoading && (
                        <ChooseCard
                          onChange={setEditCardInfo}
                          editCardInfo={editCardInfo}
                          defaultCard={!!(defaultCardInfo && defaultCardInfo.name)}
                          paypal={!user?.stripe_subscription_id}
                          stripe={!user?.paypal_subscription_id}
                        />
                      )}
                    {!cardsLoading && (
                      <>
                        {editCardInfo !== 3 ? (
                          <CreditCardForm
                            cardInfo={defaultCardInfo.name && editCardInfo === 1 ? defaultCardInfo : cardInfo}
                            loading={loading || cardsLoading}
                            error={err}
                            changeData={setCardInfo}
                            disabled={!defaultCardInfo.name ? false : editCardInfo === 1}
                            fieldError={fieldError}
                          />
                        ) : (
                          <div className="w-md-75 text-center mx-auto my-5 py-3 pay_with_paypal">
                            Click <strong>Pay with PayPal</strong> to log into your account and confirm your purchase.
                            You'll be redirected to this page to finish up.
                          </div>
                        )}
                        <PaymentFooter
                          confirm={handleConfirm}
                          paypal={editCardInfo === 3}
                          loading={subscriptionLoading}
                        />
                      </>
                    )}
                  </>
                )}
              </>
            ) : (
              <PaymentSuccess paypal={paidWithPaypal} trial={selectedPackege.trial} />
            )
          ) : (
            <PaymentLoader />
          )}
        </div>
      ) : (
        <div className="show-paypal-info">
          <span onClick={handleCloseSecondWindow} className="close-icon">
            {closeIcon}
          </span>
          <div className="paypal-wrapper">
            <div className="pb-2">{paypalBtnIcon}</div>
            Don’t see the secure PayPal browser? We’ll help you re-launch the window to complete your purchase
            <div className="cursor-pointer pt-2" onClick={handleShowSecondWindow}>
              <strong>Click to Continue</strong>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PaymentInfo;
